import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`L'azienda`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Dal 1930 l'azienda Monticelli produce `}<a parentName="p" {...{
            "href": "/it/tranciati-in-legno"
          }}>{`tranciati in legno`}</a>{` di qualità. Questa pluriennale esperienza, combinata a una genuina passione per il legno e le sue applicazioni, permette oggi ad Eredi Monticelli di continuare questa lunga tradizione e di offrire prodotti e servizi personalizzati per soddisfare le diverse esigenze dei clienti.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`La novità dei nostri laboratori è una linea di `}<a parentName="p" {...{
            "href": "/it/mobili-per-agriturismo"
          }}>{`mobili rustici`}</a>{` per agriturismo, taverne ed esterni, caratterizzata dalla tradizionale cura per i dettagli propria della produzione artigianale. La peculiarità del legno di cedro trova nei nostri mobili la sua massima espressione, permettendoci di proporvi tavoli lunghi fino a 4,40 metri che daranno originalità al vostro giardino e alla vostra casa.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Qualità, flessibilità e attenzione al dettaglio combinati con la disponibilità a relazionarsi ai clienti fanno di Eredi Monticelli la naturale scelta per il compratore esigente.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      